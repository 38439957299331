<script setup lang="ts"></script>

<template>
  <div class="size-full bg-white font-sans text-dark">
    <HeaderNavigation />
    <main data-scroll>
      <slot />
    </main>
    <Footer />
  </div>
</template>
